import assert from 'assert'
import loadable from '@loadable/component'
import { Grid, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { Layout } from '../components/Layout'
import { ActionButton } from '../components/Ring/ActionButton'
import { EngageRingFeatures } from '../components/Ring/Features'
import { Guide } from '../components/Ring/Guide'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'
import {
    numberWithCommas,
    getMaterialLabel,
    getDesignLabel,
    getCaratLabel,
} from '../utils'
import type {
    EngageringDetailQuery,
    ImageSharp,
} from '../../types/graphql-types'
import type { Carat, EngageRing, Material, Design } from '../types/ring'
import type { PageProps, HeadProps } from 'gatsby'

const Movie = loadable(() => import('../components/Movie'), { ssr: false })

export const Head: React.FC<HeadProps<EngageringDetailQuery>> = ({ data }) => {
    const ring = convertToRing(data)
    return (
        <SEO
            title={ring.name}
            description={ring.description}
            page={'ring/' + ring.code}
        >
            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org/',
                    '@type': 'Product',
                    name: ring.name,
                    description: ring.description,
                    image: ring.images.map((image) => {
                        return (
                            data.site?.siteMetadata?.siteUrl +
                            image.gatsbyImageData.images.fallback.src
                        )
                    }),
                    offers: {
                        '@type': 'Offer',
                        price: ring.price,
                        priceCurrency: 'JPY',
                    },
                })}
            </script>
        </SEO>
    )
}

const EngageRingPage: React.FC<PageProps<EngageringDetailQuery>> = ({
    data,
}) => {
    const muiTheme = useTheme()
    const ring = convertToRing(data)
    return (
        <Layout
            breadcrumbs={[
                { name: '安い婚約指輪・エンゲージリング', path: 'engagering/' },
                { name: ring.name },
            ]}
        >
            <Grid container spacing={0} component="main">
                <Grid item sm={12} xs={12}>
                    <RingName>
                        <Txt>{ring.name}</Txt>
                    </RingName>
                </Grid>
                <Grid item sm={1} xs={12} />
                <Grid item sm={5} xs={12}>
                    <RingImageWrapper>
                        <Carousel autoPlay={false} height="280px">
                            {ring.images.map((image, index) => {
                                const img = getImage(image.gatsbyImageData)
                                return (
                                    img && (
                                        <RingImage key={index}>
                                            <GatsbyImage image={img} alt="" />
                                        </RingImage>
                                    )
                                )
                            })}
                        </Carousel>
                    </RingImageWrapper>
                </Grid>
                <Grid item sm={5} xs={12}>
                    <RingInfoWrapper theme={muiTheme}>
                        <Row>
                            <PriceTxt size="l" bold>
                                {numberWithCommas(ring.price)}
                            </PriceTxt>
                            <Txt size="xs">円(税込)</Txt>
                        </Row>
                        <Row>
                            <Txt size="s">
                                {getMaterialLabel(ring.material)}
                            </Txt>
                            <DividerTxt size="s">/</DividerTxt>
                            <Txt size="s">{getDesignLabel(ring.design)}</Txt>
                            <DividerTxt size="s">/</DividerTxt>
                            <Txt size="s">{getCaratLabel(ring.carat)}</Txt>
                        </Row>
                        <Row>
                            <Txt size="s">(ダイヤ鑑別書付)</Txt>
                        </Row>
                        <Row>
                            <Txt size="s">通常納期</Txt>
                            <BoldTxt size="s" bold>
                                2週間前後
                            </BoldTxt>
                        </Row>
                        <Row>
                            <Txt size="s">特急発送</Txt>
                            <BoldTxt size="s" bold>
                                5~7日
                            </BoldTxt>
                            <Txt size="s">(応相談)</Txt>
                        </Row>
                    </RingInfoWrapper>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <RingDescription>
                        <Txt>{ring.description}</Txt>
                    </RingDescription>
                </Grid>
                <Grid item sm={3} xs={12} />
                <Grid item sm={6} xs={12} component="aside">
                    <Movie />
                </Grid>
                <Grid item sm={12} xs={12} component="aside">
                    <EngageRingFeatures />
                    <ActionButton ring={ring} />
                    <Guide cartParams={ring.cartParams} />
                </Grid>
            </Grid>
        </Layout>
    )
}

function convertToRing(data: EngageringDetailQuery): EngageRing {
    const { edges: imageEdges } = data.allFile
    const getImage = (filename: string) => {
        return imageEdges.find((edge) => {
            return edge.node.relativePath === filename
        })?.node?.childImageSharp
    }

    const ring = data.engageringsJson
    assert(ring)
    assert(ring.code)
    assert(ring.name)
    assert(ring.design)
    assert(ring.material)
    assert(ring.carat)
    assert(ring.price)
    assert(ring.images)
    assert(ring.cartParams)
    const images = ring.images.map((image) => {
        assert(image)
        return getImage('rings/' + ring.code + '/' + image) as ImageSharp
    })

    return {
        code: ring.code,
        name: ring.name,
        description: ring.description || '',
        design: ring.design as Design,
        material: ring.material as Material,
        carat: ring.carat as Carat,
        price: ring.price,
        images: images,
        cartParams: ring.cartParams,
    }
}

export const query = graphql`
    query EngageringDetail($code: String, $imageDir: String) {
        site {
            siteMetadata {
                siteUrl
            }
        }
        engageringsJson(code: { eq: $code }) {
            code
            name
            description
            design
            material
            carat
            price
            images
            cartParams
        }
        allFile(filter: { relativePath: { glob: $imageDir } }) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(
                            layout: FIXED
                            width: 340
                            height: 280
                            quality: 90
                            transformOptions: { fit: FILL }
                        )
                    }
                    relativePath
                }
            }
        }
    }
`

const RingName = styled('h1')({
    margin: '8px 0 8px',
    textAlign: 'center',
    lineHeight: 1,
})
const RingImageWrapper = styled('div')({
    height: '328px',
    padding: '8px',
})
const RingImage = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
})
const RingInfoWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px 20px',
    [theme.breakpoints.up('sm')]: {
        marginTop: '40px',
    },
}))
const RingDescription = styled('div')({
    padding: '0 20px 20px',
})
const Row = styled('div')({
    display: 'flex',
    padding: '5px 0',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
})
const PriceTxt = styled(Txt)({
    margin: '0 4px 0 8px',
    lineHeight: 1,
})
const DividerTxt = styled(Txt)({
    margin: '0 4px',
})
const BoldTxt = styled(Txt)({
    margin: '0 8px',
})

export default EngageRingPage
